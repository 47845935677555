body {
  font-family: 'Lato', sans-serif;
  color: #343a40
}

.page-header {
  margin-top: 3rem;
}

.highlighted {
  background-color: #830E4E;
}
